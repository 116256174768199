import React, { useState } from "react";
import { connect } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import PeopleIcon from "@mui/icons-material/People";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import PaymentIcon from "@mui/icons-material/Payment";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HouseIcon from "@mui/icons-material/House";
import PasswordIcon from "@mui/icons-material/Password";

import defaultLogo from "../../images/evident-logo-39.png";
import smartClinicLogo from "../../images/EviSmartLogo.png";

import AccountService from "../../services/account.service";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AccountIcon from "../../images/account_icon.png";
import MenuImg from "../../images/menu-evident.png";
import { Avatar, Button, ButtonBase, Link, Stack } from "@mui/material";
import {
  brandBlack,
  brandBlue,
  brandColor,
  brandColorActive,
  brandColorHover,
} from "../../constants";
import MenuLink from "./menuLink";
import { grey } from "@mui/material/colors";
import MenuButton from "./menuButton";
import ChangePasswordModal from "../account/changePasswordModal";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "./menuItem";
import NotificationSettingsModal from "../account/notificationSettingsModal";
import HeaderBranding from "../coBranding/headerBranding";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import MainWebsiteMenu from "./mainWebsiteMenu";
function mapStateToProps(state) {
  return { displayTheme: state.displayTheme, userInfo: state.userInfo };
}

function Header({ activeTab, displayTheme, userInfo, doctorName }) {
  const loggedIn = userInfo !== null;
  const searchParams = new URLSearchParams(window.location.search);

  const [isChangeOpen, setIsChangeOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const _apiUrl = process.env.REACT_APP_API_END_POINT.split("=")[1];

  let _loginUrl = process.env.REACT_APP_ACCOUNT_LOGIN + window.location.origin;
  if(searchParams.has('params')){
    _loginUrl += "&optionalParams=" + searchParams.get('params');
  }
  console.log(_loginUrl);
  const _createUrl = process.env.REACT_APP_ACCOUNT_CREATE_ACCOUNT;
  const _faqUrl = process.env.REACT_APP_EVIDENT_FAQ_URL;
  const _changePasswordUrl = process.env.REACT_APP_CHANGE_PASSWORD_URL;

  const _mainWebsiteUrl = loggedIn
    ? process.env.REACT_APP_EVIDENT_MAIN_LOG_IN
    : process.env.REACT_APP_EVIDENT_MAIN_URL;

  const _faqUnauthorized = _mainWebsiteUrl + "/faq";

  const pages = [
    {
      display: "Treatments",
      navigation: "/",
    },
    {
      display: "Patients",
      navigation: "/patients",
    },
    {
      display: "Case Management",
      navigation: "/cases",
    },
    {
      display: "FAQ",
      navigation: loggedIn ? _faqUrl : _faqUnauthorized,
    },
  ];

  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openDropdown = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  // const closeDropdwon = () => {
  //   setOpen(false)
  // }

  const handleChangePasswordClick = () => {
    setIsChangeOpen(true);
  };

  const handleNotificationClick = () => {
    setIsNotificationOpen(true);
  };

  const handleNotificationClose = () => {
    setIsNotificationOpen(false);
  };

  const handleModalClose = () => {
    setIsChangeOpen(false);
  };
  const openDesignPrices = async () => {
    let data = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      },
    };

    const path = "getfilesas";
    let filePath = "docs/Evident_Price_List.pdf";
    try {
      const response = await fetch(
        _apiUrl + `Blob/${path}?url=` + filePath,
        data
      );
      const downloadPath = await response.json();
      window.open(downloadPath, "_blank");
      return;
    } catch (err) {
      console.log(err);
    }
    //"docs/Evident_Price_List.pdf"
  };

  let name = null;
  let userRole = null;
  if (userInfo) {
    const fullName = `${userInfo.FirstName} ${userInfo.LastName}`;
    const isDoctor = userInfo.Roles.includes("Dentist");
    const doctorPrefix = isDoctor ? "Dr. " : "";

    if (fullName.length > 35) {
      name = doctorPrefix + userInfo.LastName;
    } else {
      name = doctorPrefix + fullName;
    }
    console.log(userInfo);
    userRole = userInfo.Roles[0]
  }

  if (doctorName !== undefined && doctorName !== null && doctorName !== "") {
    name = doctorName;
  }

  return (
    <AppBar
      position="static"
      sx={{
        height: "87px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        boxShadow: "0px 7px 11px rgba(255, 255, 255, 0)",
      }}
    >
      <ChangePasswordModal open={isChangeOpen} onClose={handleModalClose} />
      <NotificationSettingsModal
        open={isNotificationOpen}
        onClose={handleNotificationClose}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        height="51px"
        maxWidth="1536px"
        margin="0 auto"
        position="relative"
      >
        <Stack direction="row" alignItems="center" height="100%" flex={1}>
          <Link
            // component={RouterLink}
            href={_mainWebsiteUrl}
            sx={{
              mr: 2,
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}
          >
            <img src={smartClinicLogo} alt="Evident" style={{ maxHeight: "44px" }}/>
          </Link>

          <HeaderBranding />
        </Stack>
        {userInfo !== null ? (
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              textAlign: "center",
              justifyContent: "center",
              height: "100%",
              gap: 6,
            }}
          >
            {pages.map((page, index) => (
              <Box
                key={page.display}
                component={RouterLink}
                to={page.navigation}
                sx={{
                  textDecoration: "none",
                  position: "relative",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  cursor: "pointer",
                  color: activeTab === page.navigation ? "#000" : "#000",
                  display: "flex",
                  alignItems: "center",
                  borderBottom:
                    activeTab === page.navigation
                      ? `2px solid #09CF35`
                      : `2px solid transparent`,
                }}
              >
                <Typography
                  variant="paragraph"
                  sx={{
                    fontSize: "14px",
                    color: activeTab === page.navigation ? "#111111" : "#555555",
                    fontWeight: activeTab === page.navigation ? "600" : "400",
                  }}
                >
                  {page.display}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Box></Box>
        )}
        <Stack
          sx={{
            display: "flex",
            height: "100%",
            textAlign: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          spacing="20px"
          direction="row"
          flex={1}
        >
          {/* {doctorName !== undefined &&
            doctorName !== null &&
            doctorName !== "" && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginRight={1}
              >
                <Typography fontSize="16px">Hi {name}</Typography>
              </Box>
            )} */}
          {userInfo !== null ? (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                width: "max-content",
              }}
            >
              {/* <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginRight={1}
                sx={{color:"#111111"}}
              >
                <Typography fontSize="16px">{name}</Typography>
              </Box> */}

              <Stack direction="row" spacing={1} alignItems="center" sx={{margin: '15px'}}>
                {/* Avatar */}
                <Avatar sx={{ bgcolor: "#FF4081" }}>{name[0]}</Avatar>
                
                {/* Text Content */}
                <Stack alignItems="baseline">
                  <Typography fontSize="14px" fontWeight={600} color="#111111">
                    {name}
                  </Typography>
                  <Typography fontSize="12px" fontWeight={400} color="#555555">
                    {userRole}
                  </Typography>
                </Stack>
              </Stack>


              <IconButton
                variant="contained"
                size="medium"
                onClick={openDropdown}
                disableRipple={true}
                sx={{
                  color: "#9999999",
                  background: "#ffffff",
                  borderRadius: "30px",
                  "&: hover": {
                    background: "#ffffff",
                  },
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
              <Popper
                id={"popper"}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
              >
                <Paper
                  sx={{
                    padding: 2,
                    marginTop: 1,
                    borderWidth: "1px",
                    borderColor: grey[50],
                    borderRadius: 2,
                  }}
                >
                  <MenuLink
                    to="/profile"
                    icon={<AccountCircleIcon />}
                    active={location.pathname === "/profile"}
                    title="Profile"
                  />

                  {userInfo.Roles.includes("Dentist") && (
                    <MenuItem onClick={handleNotificationClick}>
                      <NotificationsIcon />
                      <Typography
                        as="span"
                        sx={{ marginLeft: 1, fontWeight: "400" }}
                      >
                        Notification Settings
                      </Typography>
                    </MenuItem>
                  )}

                  <MenuLink
                    to="/labs"
                    icon={<HouseIcon />}
                    active={location.pathname === "/labs"}
                    title="Labs"
                  />
                  {userInfo.Roles.includes("Dentist") && (
                    <>
                      <MenuLink
                        to="/users"
                        active={location.pathname === "/users"}
                        icon={<PeopleIcon />}
                        title="Users"
                      />
                      <MenuLink
                        to="/paymentDetails"
                        active={location.pathname === "/paymentDetails"}
                        icon={<PaymentIcon />}
                        title="Payment"
                      />
                    </>
                  )}

                  <MenuLink
                    to="/videos"
                    active={location.pathname === "/videos"}
                    icon={<OndemandVideoIcon />}
                    title="Video Tutorials"
                  />
                  <MenuLink
                    to="/designpreferences"
                    active={location.pathname === "/designpreferences"}
                    icon={<SettingsAccessibilityIcon />}
                    title="Design Preferences"
                  />

                  <a
                    href="https://evidentdigital.blob.core.windows.net/public/DENTIST_PRICELIST.pdf"
                    style={{ color: "inehrit", textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MenuItem>
                      <RequestQuoteIcon />
                      <Typography
                        as="span"
                        sx={{ marginLeft: 1, fontWeight: "400" }}
                      >
                        Design Prices
                      </Typography>
                    </MenuItem>
                  </a>
                  <a
                    href={_changePasswordUrl}
                    style={{ color: "inehrit", textDecoration: "none" }}
                    // target="_blank"
                    rel="noreferrer"
                  >
                    <MenuButton
                      icon={<PasswordIcon />}
                      title="Change Password"

                      // onClick={handleChangePasswordClick}
                    />
                  </a>
                  <Box
                    component={ButtonBase}
                    disableRipple
                    sx={{
                      width: "100%",
                      display: "flex",
                      cursor: "pointer",
                      bgcolor: grey[200],
                      marginTop: 3,
                      padding: 1,
                      borderRadius: 2,
                      ":hover": {
                        bgcolor: grey[300],
                      },
                    }}
                    onClick={async () => {
                      await AccountService.logOut();
                      window.localStorage.clear();
                      window.location.href = _loginUrl;
                    }}
                  >
                    <Typography fontWeight={500}>Log Out</Typography>
                  </Box>
                </Paper>
              </Popper>
            </Box>
          ) : (
            <>
              {(doctorName === undefined ||
                doctorName === null ||
                doctorName === "") && (
                <React.Fragment>
                  <Box
                    sx={{
                      backgroundColor: brandColor,
                      color: "white",
                      fontSize: "1em",
                      lineHeight: "1.2em",
                      paddingX: 2.5,
                      paddingY: 1,
                      borderRadius: "30px",
                      ":hover": {
                        backgroundColor: brandColorHover,
                      },
                      ":active": {
                        backgroundColor: brandColorActive,
                      },
                    }}
                  >
                    <a
                      underline="none"
                      component={RouterLink}
                      to="/login"
                      style={{
                        margin: "0px",
                        width: "max-content",
                        display: 'inline-block',
                        fontWeight: "400",
                        fontSize: "1rem",
                        lineHeight: "1.5",
                        textDecoration: "none",
                        color: "white",
                        display: 'inline-block',
                        width: 'max-content'
                      }}
                      href={_loginUrl}
                    >
                      <Typography>Get Started</Typography>
                    </a>
                  </Box>
                  {/* <Box
                    underline="none"
                    component={"a"}
                    sx={{
                      lineHeight: "1.5",
                      textDecoration: "none",
                      padding: "20px 13px",
                      color: "white",
                      "&: hover": {
                        color: brandBlue,
                      },
                    }}
                    href={_loginUrl}
                  >
                    <Typography fontSize="15px">Log in</Typography>
                  </Box> */}
                </React.Fragment>
              )}
            </>
          )}
          {/* <Box
            underline="none"
            component={"a"}
            sx={{
              lineHeight: "1.5",
              textDecoration: "none",
              padding: "20px 13px",
              color: "white",
              "&: hover": {
                color: brandBlue,
              },
            }}
            href={loggedIn ? _faqUrl : _faqUnauthorized}
          >
            <Typography fontSize="16px">FAQ</Typography>
          </Box> */}
          {/* {userInfo !== null ? (
            <MainWebsiteMenu loggedIn />
          ) : (
            <MainWebsiteMenu />
          )} */}
        </Stack>
      </Stack>
    </AppBar>
  );
}

export default connect(mapStateToProps, null)(Header);
