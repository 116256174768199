import React from "react";

import Box from "@mui/material/Box";

function SubHeader() {
  return (
    <Box
      position="relative"
      sx={{
        width: "100%",
        height: "99px",
        background: "#fff",
      }}
    >
    </Box>
  );
}

export default SubHeader;
