export const brandColor = "#03C75A";
export const brandColorHover = "#6dc174";
export const brandColorActive = "#179647";
export const brandBlue = "#48add9";
export const brandGradient = "linear-gradient(90deg, #48add9 0%, #03C75A 100%)";
export const headerGradient =
  "linear-gradient(90deg, #48add9 0%, #03C75A 100%)";
export const apiUrl = process.env.REACT_APP_API_END_POINT.split("=")[1];
export const setNamesForUpload = [
  "Clinical Photography",
  "IOS Scan (STL Files) (Upper, Lower and Bite)",
];
export const brandRed = "#ef3401";
export const brandOrange = "#e66400";
export const brandGray = "#7f7f7f";
export const brandLight = "#d9d9d978";
export const brandBlack = "#231f20";
export const brandLightGreen = "#6dc174";
export const allOnXData = [
  {
    "id": "88a1ade1-016a-47a4-890e-a2bec7743165",
    "description": "All on X Implants",
    "productId": 1214,
    "imageLink": "https://evidentdigital.blob.core.windows.net/public/treatmenttypeimages/aox3.png",
    "price": 0,
    "toothNumbers": [],
    "files": [],
    "caseNotes": "",
    "additionalNotes": "",
    "dueDate": "",
    "requirements": [
        {
            "description": "Treatment Requirement",
            "orderNumber": 1,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithChildComponent",
            "itemList": [
                {
                    "item": "PMMA (Temporary) Only"
                },
                {
                    "item": "Final Restoration (Zirconia) Only"
                },
                {
                    "item": "Both Temp and Final"
                }
            ]
        },
        {
            "description": "This All on X is For",
            "orderNumber": 2,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithChildComponent",
            "itemList": [
                {
                    "item": "Upper Arch Only"
                },
                {
                    "item": "Lower Arch Only"
                },
                {
                    "item": "Both Upper And Lower Arches"
                }
            ]
        },
        {
            "description": "Implant Manufacturer",
            "orderNumber": 3,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithOtherSpecifyComponent",
            "itemList": {
                "items": [
                    "Straumann",
                    "Dess",
                    "Argen",
                    "Open Implants",
                    "Others"
                ],
                "itemWithSpecify": "Others",
                "placeholder": "Please Specify",
                "adornment": "",
                "fieldTypeIsNumber": false
            }
        },
        {
            "description": "Using Photogrammetry / OPTISPLINT Workflow?",
            "orderNumber": 4,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithChildComponent",
            "itemList": [
                {
                    "item": "ICAM/PIC",
                    "child": [
                        {
                            "name": "ExportLabel",
                            "placeholder": "Export complete file in alignment & upload in Additional Files Section.",
                            "type": "label"
                        }
                    ]
                },
                {
                    "item": "Optisplint/Micronmapper",
                   
                },
                {
                    "item": "No",
                    "child": [
                        {
                            "name": "ImplantScanbody",
                            "placeholder": "Implant Scanbody",
                            "adornment": "",
                            "type": "textfield"
                        }
                    ]
                }
            ]
        },
        {
            "description": "Restorative Options",
            "orderNumber": 5,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithChildComponent",
            "itemList": [
                {
                    "item": "Direct To MUA"
                },
                {
                    "item": "With MUA Cap (Sleeves)"
                },
                {
                    "item": "Direct to Fixture (Ti-base)"
                }
            ]
        },
        {
            "description": "Prosthetic Screw",
            "orderNumber": 6,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithOtherSpecifyComponent",
            "itemList": {
                "items": [
                    "Dess [19.018]",
                    "Rosen Screw",
                    "Powerball",
                    "Other"
                ],
                "itemWithSpecify": "Other",
                "placeholder": "Please Specify",
                "adornment": "",
                "fieldTypeIsNumber": false
            }
        },
        {
            "description": "Design Type",
            "orderNumber": 7,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithChildComponent",
            "itemList": [
                {
                    "item": "FP1"
                },
                {
                    "item": "FP2"
                },
                {
                    "item": "Ideal"
                }
            ]
        },
        {
            "description": "Do you have an existing wax-up of denture design to follow?",
            "orderNumber": 8,
            "value": null,
            "selectionValue": null,
            "componentType": "ListWithChildComponent",
            "itemList": [
                {
                    "item": "Yes",
                    "child": [
                        {
                            "name": "caseNumber",
                            "placeholder": "Select Case number",
                            "type": "caseNumber",
                            "productIds": [
                                1046
                            ]
                        }
                    ]
                },
                {
                    "item": "No"
                }
            ]
        }
    ],
    "treatmentCategoryId": "27770f04-1d69-4d6d-898f-b9b08a510264",
    "productDescription": "A full arch restorative solution using a minimally invasive method to provide a functional and esthetic result.",
    "productSubDescription": "",
    "treatmentCategoryDescription": "Implants",
    "treatmentTypeServiceProviders": [
        {
            "serviceProvider": "Design",
            "showInvitedLabs": false,
            "showDesignCenter": true,
            "certifiedPartners": null,
            "designCenterName": "Evident Design Center",
            "displayName": "Design",
            "orderNumber": 1
        }
    ],
    "serviceProviderSelected": "Design"
}
]