import {
  Autocomplete,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../components/common/contentContainer";
import Header from "../../components/header/header";
import AdminService from "../../services/admin.service";

import LargeButton from "../../components/common/button/largeButton";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../components/common/inputText";
import Tabs from "../../components/designPreferences/tabs";
import { useTheme } from "@mui/material/styles";

const DesignPreferences = () => {
  const theme = useTheme();
  const [dentists, setDentists] = useState([]);
  const [selectedDentist, setSelectedDentist] = useState();
  const [selectedDentistEmail, setSelectedDentistEmail] = useState();
  const [preferences, setPreferences] = useState([]);
  const [originalPreferences, setOriginalPreferences] = useState([]);
  const userInfo = useSelector((state) => state.userInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("view");
  const { control, register, handleSubmit } = useForm();

  const getDentists = async () => {
    const dentistResponse = await AdminService.getDentists(userInfo.Email);
    const { data } = await dentistResponse.json();

    let datas = [];
    data.forEach((dt) => {
      let d = {
        id: `${dt.dentistId}`,
        name: `${dt.dentistFirstName} ${dt.dentistLastName}`,
        email: `${dt.dentistEmail}`,
      };
      datas.push(d);
    });
    console.log(data);
    setDentists(datas);
    setSelectedDentist(data[0].dentistId);
    setSelectedDentistEmail(data[0].dentistEmail);
  };

  const getDentistPreferences = async () => {
    setIsLoading(true);
    console.log(selectedDentist);
    const response = await AdminService.getdesignpreferencesbyid(
      selectedDentist
    );
    const data = await response.json();

    setIsLoading(false);
    let preferenceData = [];
    console.log(data);
    if (data.data.length > 0) {
      data.data.forEach((pref) => {
        console.log(pref.personnelId);
        let p = {
          personnelId: pref.personnelId,
          preference: pref.preference,
          label: "",
          value: pref.value,
          options: [],
          category: "",
        };

        if (pref.preference === "HubPonticTypes") {
          p.label = "Pontic types";
          p.options = [
            "",
            "Modified Ridge Lap",
            "Hygienic",
            "Saddle Ridge",
            "Conical",
          ];
          p.category = "Crown & Bridge";
          p.preferenceType = 2;
        } else if (pref.preference === "HubTypesOfContact") {
          p.label = "Types of contact";
          p.options = ["", "Pinpoint", "Broad"];
          p.category = "Crown & Bridge";
          p.preferenceType = 2;
        } else if (pref.preference === "HubAnatomy") {
          p.label = "Anatomy";
          p.options = ["", "Primary", "Secondary", "Others"];
          p.category = "Crown & Bridge";
          p.preferenceType = 2;
        } else if (pref.preference === "HubTemporaries") {
          p.label = "Temporaries";
          p.options = [
            "",
            "Shell Crowns",
            "Temporary Crown & Bridge",
            "Snap-on Smile",
          ];
          p.category = "Crown & Bridge";
          p.preferenceType = 2;
        } else if (
          pref.preference === "HubDifferentTypesOfScrewRetainedImplants"
        ) {
          p.label = "Different types of Screw-Retained Implants";
          p.options = [
            "",
            // "Screw-Retained Crown on Ti Base",
            "Screw-Retained Crown",
            // "Cement-Retained Crown + Custom Abutment on Ti Base",
            "Cement-Retained Crown + Custom Abutment",
            // "Screw-Mentable Crown + Custom Abutment",
            "Crown w/ Access Hole / Crown over Ti Base/Abutment",
          ];
          p.category = "Implants";
          p.preferenceSubType = "select-lg";
          p.preferenceType = 3;
        } else if (pref.preference === "HubDifferentTypesOfCustomAbutment") {
          p.label = "Different types of Custom Abutment";
          p.options = ["", "With bevel", "With slit"];
          p.category = "Implants";
          p.preferenceType = 2;
        } else if (pref.preference === "HubNightguardThickness") {
          p.label = "Nightguard Thickness";
          p.category = "Appliances/Nightguards";
          p.preferenceSubType = "measurement";
          p.preferenceType = 0;
        } else if (pref.preference === "HubOcclusalSurface") {
          p.label = "Occlusal Surface";
          p.options = [
            "",
            "Smooth",
            "Mild opposing indentation",
            "Deep opposing indentation",
          ];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubNightguardManufacturingTechnique") {
          p.label = "Manufacturing Technique";
          p.options = ["", "Print", "Mill"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubBuccalCoverage") {
          p.label = "Buccal Coverage";
          p.options = ["", "1/2", "1/3", "2/3", "Full coverage"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubLabialCoverage") {
          p.label = "Labial Coverage";
          p.options = ["", "1/2", "1/3", "2/3", "Full coverage"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubLingualCoverage") {
          p.label = "Lingual Coverage";
          p.options = ["", "1/2", "1/3", "2/3", "Full coverage"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubIndentation") {
          p.label = "Indentation";
          p.options = ["", "Pinpoint", "Aggressive", "Mild"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubScallopingOnEdge") {
          p.label = "Scalloping on Edge";
          p.options = ["", "Yes", "No"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubOffset") {
          p.label = "Offset";
          p.options = ["", "0.01", "0.02", "0.03", "0.04", "0.05"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 3;
        } else if (pref.preference === "HubRetention") {
          p.label = "Retention";
          p.options = ["", "0.03", "0.04", "0.05", "0.06", "0.07"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 3;
        } else if (pref.preference === "HubMaterialToUse") {
          p.label = "Material to use";
          p.options = ["", "Soft", "Hard"];
          p.category = "Appliances/Nightguards";
          p.preferenceType = 2;
        } else if (pref.preference === "HubCaseToBeDesignedAs") {
          p.label = "Case to be designed as";
          p.options = ["", "Monoblock", "Teeth separate from base"];
          p.category = "Dentures";
          p.preferenceType = 3;
        } else if (pref.preference === "HubToothSegmentation") {
          p.label = "Tooth segmentation";
          p.options = ["", "Singles", "Quad", "Sextants", "One piece"];
          p.category = "Dentures";
          p.preferenceType = 3;
        } else if (pref.preference === "HubDesiredOverjetMeasurement") {
          p.label = "Desired overjet measurement";
          p.category = "Dentures";
          p.preferenceSubType = "measurement";
          p.preferenceType = 0;
        } else if (pref.preference === "HubDesiredOverbiteMeasurement") {
          p.label = "Desired overbite measurement";
          p.category = "Dentures";
          p.preferenceSubType = "measurement";
          p.preferenceType = 0;
        } else if (pref.preference === "HubDentureTeethShape") {
          p.label = "Denture teeth shape";
          p.options = ["", "Ovoid", "Squarish", "Tapering"];
          p.category = "Dentures";
          p.preferenceType = 2;
        } else if (pref.preference === "HubDenturesManufacturingTechnique") {
          p.label = "Manufacturing Technique";
          p.options = ["", "Print", "Mill"];
          p.category = "Dentures";
          p.preferenceType = 2;
        } else if (pref.preference === "HubFestooningPreferences") {
          p.label = "Festooning preferences";
          p.options = ["", "Smooth", "Medium", "Detailed"];
          p.category = "Dentures";
          p.preferenceType = 2;
        } else if (pref.preference === "HubGeneralPreference") {
          p.label = "General Preference";
          p.category = "General";
          p.preferenceType = 8;
        }
        preferenceData.push(p);
      });

      setPreferences(preferenceData);
    } else {
      let prefs = [
        {
          personnelId: selectedDentist,
          preference: "HubPonticTypes",
          label: "Pontic types",
          value: "",
          preferenceType: 2,
          options: [
            "",
            "Modified Ridge Lap",
            "Hygienic",
            "Saddle Ridge",
            "Conical",
          ],
          category: "Crown & Bridge",
        },
        {
          personnelId: selectedDentist,
          preference: "HubTypesOfContact",
          label: "Types of contact",
          value: "",
          preferenceType: 2,
          options: ["", "Pinpoint", "Broad"],
          category: "Crown & Bridge",
        },
        {
          personnelId: selectedDentist,
          preference: "HubAnatomy",
          label: "Anatomy",
          value: "",
          preferenceType: 2,
          options: ["", "Primary", "Secondary", "Others"],
          category: "Crown & Bridge",
        },
        {
          personnelId: selectedDentist,
          preference: "HubTemporaries",
          label: "Temporaries",
          value: "",
          preferenceType: 2,
          options: [
            "",
            "Shell Crowns",
            "Temporary Crown & Bridge",
            "Snap-on Smile",
          ],
          category: "Crown & Bridge",
        },
        {
          personnelId: selectedDentist,
          preference: "HubDifferentTypesOfScrewRetainedImplants",
          label: "Different types of Screw-Retained Implants",
          value: "",
          preferenceType: 3,
          options: [
            "",
            // "Screw-Retained Crown on Ti Base",
            "Screw-Retained Crown",
            // "Cement-Retained Crown + Custom Abutment on Ti Base",
            "Cement-Retained Crown + Custom Abutment",
            // "Screw-Mentable Crown + Custom Abutment",
            "Crown w/ Access Hole / Crown over Ti Base/Abutment",
          ],
          preferenceSubType: "select-lg",
          category: "Implants",
        },
        {
          personnelId: selectedDentist,
          preference: "HubDifferentTypesOfCustomAbutment",
          label: "Different types of Custom Abutment",
          value: "",
          preferenceType: 2,
          options: ["", "With bevel", "With slit"],
          category: "Implants",
        },
        {
          personnelId: selectedDentist,
          preference: "HubNightguardThickness",
          label: "Nightguard Thickness",
          value: "",
          preferenceType: 0,
          preferenceSubType: "measurement",
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubOcclusalSurface",
          label: "Occlusal Surface",
          value: "",
          preferenceType: 2,
          options: [
            "",
            "Smooth",
            "Mild opposing indentation",
            "Deep opposing indentation",
          ],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubNightguardManufacturingTechnique",
          label: "Manufacturing Technique",
          value: "",
          preferenceType: 2,
          options: ["", "Print", "Mill"],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubBuccalCoverage",
          label: "Buccal Coverage",
          value: "",
          preferenceType: 2,
          options: ["", "1/2", "1/3", "2/3", "Full coverage"],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubLabialCoverage",
          label: "Labial Coverage",
          value: "",
          preferenceType: 2,
          options: ["", "1/2", "1/3", "2/3", "Full coverage"],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubLingualCoverage",
          label: "Lingual Coverage",
          value: "",
          preferenceType: 2,
          options: ["", "1/2", "1/3", "2/3", "Full coverage"],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubIndentation",
          label: "Indentation",
          value: "",
          preferenceType: 2,
          options: ["", "Pinpoint", "Aggressive", "Mild"],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubScallopingOnEdge",
          label: "Scalloping on Edge",
          value: "",
          preferenceType: 2,
          options: ["", "Yes", "No"],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubOffset",
          label: "Offset",
          value: "",
          preferenceType: 3,
          options: ["", "0.01", "0.02", "0.03", "0.04", "0.05"],
          preferenceSubType: "select-sm",
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubRetention",
          label: "Retention",
          value: "",
          preferenceType: 3,
          options: ["", "0.03", "0.04", "0.05", "0.06", "0.07"],
          preferenceSubType: "select-sm",
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubMaterialToUse",
          label: "Material to use",
          value: "",
          preferenceType: 2,
          options: ["", "Soft", "Hard"],
          category: "Appliances/Nightguards",
        },
        {
          personnelId: selectedDentist,
          preference: "HubCaseToBeDesignedAs",
          label: "Case to be designed as",
          value: "",
          preferenceType: 3,
          options: ["", "Monoblock", "Teeth separate from base"],
          preferenceSubType: "choices-md",
          category: "Dentures",
        },
        {
          personnelId: selectedDentist,
          preference: "HubToothSegmentation",
          label: "Tooth segmentation",
          value: "",
          preferenceType: 3,
          options: ["", "Singles", "Quad", "Sextants", "One piece"],
          preferenceSubType: "select-sm",
          category: "Dentures",
        },
        {
          personnelId: selectedDentist,
          preference: "HubDesiredOverjetMeasurement",
          label: "Desired overjet measurement",
          value: "",
          preferenceType: 0,
          preferenceSubType: "measurement",
          category: "Dentures",
        },
        {
          personnelId: selectedDentist,
          preference: "HubDesiredOverbiteMeasurement",
          label: "Desired overbite measurement",
          value: "",
          preferenceType: 0,
          preferenceSubType: "measurement",
          category: "Dentures",
        },
        {
          personnelId: selectedDentist,
          preference: "HubDentureTeethShape",
          label: "Denture teeth shape",
          value: "",
          preferenceType: 3,
          options: ["", "Ovoid", "Squarish", "Tapering"],
          preferenceSubType: "select-sm",
          category: "Dentures",
        },
        {
          personnelId: selectedDentist,
          preference: "HubDenturesManufacturingTechnique",
          label: "Manufacturing Technique",
          value: "",
          preferenceType: 3,
          options: ["", "Print", "Mill"],
          preferenceSubType: "select-sm",
          category: "Dentures",
        },
        {
          personnelId: selectedDentist,
          preference: "HubFestooningPreferences",
          label: "Festooning preferences",
          value: "",
          preferenceType: 3,
          options: ["", "Smooth", "Medium", "Detailed"],
          preferenceSubType: "select-sm",
          category: "Dentures",
        },
        {
          personnelId: selectedDentist,
          preference: "HubGeneralPreference",
          label: "General Preference",
          value: "",
          preferenceType: 8,
          category: "General",
        },
      ];

      setPreferences(prefs);
    }
  };

  const handleTabsOnChange = (id) => {
    setMode("view");
    console.log(dentists.find((x) => x.id === id));
    let d = dentists.find((x) => x.id === id);

    setSelectedDentist(d.id);
    setSelectedDentistEmail(d.email);
  };

  useEffect(() => {
    if (userInfo.Roles.includes("Dentist")) {
      setSelectedDentist(userInfo.Id);
      setSelectedDentistEmail(userInfo.Email);
    } else {
      getDentists();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDentistPreferences();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDentist]);

  const hookField = (pr) => {
    if (pr.preferenceType === 2 || pr.preferenceType === 3) {
      return (
        <React.Fragment>
          <Controller
            control={control}
            name={pr.preference}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Autocomplete
                value={pr.value}
                autoHighlight
                disablePortal
                options={pr.options}
                getOptionLabel={(option) => option.description || option}
                onChange={(_, option) => {
                  onChange(option);

                  let newArr = preferences.map((item, i) => {
                    if (item.preference === pr.preference) {
                      return {
                        ...item,
                        value: option,
                      };
                    } else {
                      return item;
                    }
                  });
                  setPreferences(newArr);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={Boolean(error)}
                    FormHelperTextProps={{
                      variant: "standard",
                    }}
                    helperText={error && error.message}
                  />
                )}
              />
            )}
          />
        </React.Fragment>
      );
    } else if (pr.preferenceType === 0) {
      return (
        <InputText
          value={pr.value}
          onChange={(e) => {
            let newArr = preferences.map((item, i) => {
              if (item.preference === pr.preference) {
                return {
                  ...item,
                  value: e.target.value,
                };
              } else {
                return item;
              }
            });
            setPreferences(newArr);
          }}
          type="number"
          inputprops={{
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
          }}
        />
      );
    } else if (pr.preferenceType === 8) {
      return (
        <InputText
          multiline
          rows={3}
          sx={{
            ".MuiFormControl-root": {
              width: "50%",
            },
          }}
          value={pr.value}
          onChange={(e) => {
            let newArr = preferences.map((item, i) => {
              if (item.preference === pr.preference) {
                return {
                  ...item,
                  value: e.target.value,
                };
              } else {
                return item;
              }
            });
            setPreferences(newArr);
          }}
          register={register(pr.preference)}
        />
      );
    }
  };

  const savePreferences = async () => {
    setIsLoading(true);
    await AdminService.upsertdesignpreferences(
      selectedDentistEmail,
      preferences
    ).then((res) => {
      setIsLoading(false);
      setMode("view");
    });
  };

  return (
    <React.Fragment>
      <Header />
      <Container
        sx={{ marginY: "35px" }}
        maxWidth="lg"
        disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
      >
        <Box marginY={5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" component="h1">
              Design Preferences
            </Typography>

            <Stack direction="row" alignItems="center" spacing={1}>
              {mode === "view" && (
                <LargeButton
                  variant="secondary"
                  onClick={() => {
                    setMode("edit");
                    console.log(preferences);
                    setOriginalPreferences(preferences);
                  }}
                >
                  Update Preferences
                </LargeButton>
              )}
              {mode === "edit" && (
                <>
                  <LargeButton
                    variant="primary"
                    loading={isLoading}
                    onClick={() => {
                      savePreferences();
                    }}
                  >
                    Save
                  </LargeButton>
                  <LargeButton
                    variant="gray"
                    onClick={() => {
                      setMode("view");
                      setPreferences(originalPreferences);
                    }}
                  >
                    Cancel
                  </LargeButton>
                </>
              )}
            </Stack>
          </Stack>
        </Box>

        {dentists.length > 0 && (
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography variant="h4" component="h1" marginY={2}>
                Cases List
              </Typography>
              <Tabs
                data={dentists}
                selected={selectedDentist}
                onPracticeChange={handleTabsOnChange}
              />
            </Box>
          </Stack>
        )}

        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", paddingY: 4 }}>
            <CircularProgress color="success" />
          </Box>
        )}

        {!isLoading && (
          <>
            <ContentContainer sx={{ marginBottom: 2 }}>
              <Box marginY={2}>
                <Typography variant="h5" component="h2">
                  Crown & Bridge
                </Typography>
              </Box>
              <hr />
              {preferences.length > 0 &&
                preferences
                  .filter((x) => x.category === "Crown & Bridge")
                  .map((pr, i) => (
                    <React.Fragment key={i}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ marginX: 2, marginY: 3 }}
                      >
                        <Grid item sm={4}>
                          <Typography variant="body1">{pr.label}:</Typography>
                        </Grid>
                        <Grid item sm={4}>
                          {mode === "view" && (
                            <Typography variant="body1">
                              {pr.value === "" ? "N/A" : pr.value}
                            </Typography>
                          )}

                          {mode === "edit" && hookField(pr)}
                        </Grid>
                      </Grid>
                      <hr />
                    </React.Fragment>
                  ))}
            </ContentContainer>

            <ContentContainer sx={{ marginY: 2 }}>
              <Box marginY={2}>
                <Typography variant="h5" component="h2">
                  Implants
                </Typography>
              </Box>
              <hr />
              {preferences.length > 0 &&
                preferences
                  .filter((x) => x.category === "Implants")
                  .map((pr, i) => (
                    <React.Fragment key={i}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ marginX: 2, marginY: 3 }}
                      >
                        <Grid item sm={4}>
                          <Typography variant="body1">{pr.label}:</Typography>
                        </Grid>
                        <Grid item sm={4}>
                          {mode === "view" && (
                            <Typography variant="body1">
                              {pr.value === "" ? "N/A" : pr.value}
                            </Typography>
                          )}
                          {mode === "edit" && hookField(pr)}
                        </Grid>
                      </Grid>
                      <hr />
                    </React.Fragment>
                  ))}
            </ContentContainer>

            <ContentContainer sx={{ marginY: 2 }}>
              <Box marginY={2}>
                <Typography variant="h5" component="h2">
                  Appliances/Nightguards
                </Typography>
              </Box>
              <hr />
              {preferences.length > 0 &&
                preferences
                  .filter((x) => x.category === "Appliances/Nightguards")
                  .map((pr, i) => (
                    <React.Fragment key={i}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ marginX: 2, marginY: 3 }}
                      >
                        <Grid item sm={4}>
                          <Typography variant="body1">{pr.label}:</Typography>
                        </Grid>
                        <Grid item sm={4}>
                          {mode === "view" && (
                            <Typography variant="body1">
                              {pr.value === "" ? "N/A" : pr.value}
                            </Typography>
                          )}
                          {mode === "edit" && hookField(pr)}
                        </Grid>
                      </Grid>
                      <hr />
                    </React.Fragment>
                  ))}
            </ContentContainer>

            <ContentContainer sx={{ marginY: 2 }}>
              <Box marginY={2}>
                <Typography variant="h5" component="h2">
                  Dentures
                </Typography>
              </Box>
              <hr />
              {preferences.length > 0 &&
                preferences
                  .filter((x) => x.category === "Dentures")
                  .map((pr, i) => (
                    <React.Fragment key={i}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ marginX: 2, marginY: 3 }}
                      >
                        <Grid item sm={4}>
                          <Typography variant="body1">{pr.label}:</Typography>
                        </Grid>
                        <Grid item sm={4}>
                          {mode === "view" && (
                            <Typography variant="body1">
                              {pr.value === "" ? "N/A" : pr.value}
                            </Typography>
                          )}
                          {mode === "edit" && hookField(pr)}
                        </Grid>
                      </Grid>
                      <hr />
                    </React.Fragment>
                  ))}
            </ContentContainer>

            <ContentContainer sx={{ marginY: 2 }}>
              <Box marginY={2}>
                <Typography variant="h5" component="h2">
                  General
                </Typography>
              </Box>
              <hr />
              {preferences.length > 0 &&
                preferences
                  .filter((x) => x.category === "General")
                  .map((pr, i) => (
                    <React.Fragment key={i}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ marginX: 2, marginY: 3 }}
                      >
                        <Grid item sm={4}>
                          <Typography variant="body1">{pr.label}:</Typography>
                        </Grid>
                        <Grid item sm={8} sx={{ paddingRight: "20px" }}>
                          {mode === "view" && (
                            <Typography variant="body1">
                              {pr.value === "" ? "N/A" : pr.value}
                            </Typography>
                          )}
                          {mode === "edit" && hookField(pr)}
                        </Grid>
                      </Grid>
                      <hr />
                    </React.Fragment>
                  ))}
            </ContentContainer>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

export default DesignPreferences;
