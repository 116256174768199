import {
  CircularProgress,
  Container,
  getListSubheaderUtilityClass,
  Pagination,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import StyledButton from "../../components/common/button/largeButton";
import ContentContainer from "../../components/common/contentContainer";
import Header from "../../components/header/header";
import { apiUrl } from "../../constants";
import { getAuthorizedFetchOptions } from "../../helpers/getAuthorizedFetchOptions";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/common/table/dataTable";
import TableRow from "../../components/common/table/tableRow";
import ResendUserInvite from "../../components/users/resendUserInvite";
import usePaginationValues from "../../hooks/usePaginationValues";
import EmptyAlert from "../../components/patient/emptyAlert";
import NewUserDialog from "../../components/users/newUserDialog";
import PatientService from "../../services/patient.service";
import AdminService from "../../services/admin.service";
import { openSnackbar } from "../../redux/actions";
import { useTheme } from "@mui/material/styles";

const Users = () => {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [dialogDetails, setDialogDetails] = useState({
    practices: [],
    positions: [],
  });
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFetchingDialogDetails, setIsFetchingDialogDetails] = useState(true);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const { currentPage, skip, pagesCount, handleChangePage } =
    usePaginationValues(10, count);

  useEffect(() => {
    if (isSubmitting) return;
    let mounted = true;
    const getUsers = async () => {
      const params = {
        personnelId: userInfo.Id,
        skip: skip,
        take: 10,
      };
      const searchParams = new URLSearchParams(params).toString();

      const response = await fetch(
        apiUrl + "dentist/getusers?" + searchParams,
        getAuthorizedFetchOptions("GET")
      );
      const { totalCount, users } = await response.json();

      if (mounted) {
        setUsers(users);
        setCount(totalCount);
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    getUsers();

    return () => {
      mounted = false;
    };
  }, [skip, isSubmitting]);

  const handleClickPagination = (_, page) => {
    handleChangePage(page);
  };

  const handleDialogOpen = async () => {
    setIsDialogOpen(true);
    setIsFetchingDialogDetails(true);

    const practicesResponse = await AdminService.getPractices(userInfo.Id);
    const { practices } = await practicesResponse.json();

    const positionsResponse = await AdminService.getUserPositions();
    const positions = await positionsResponse.json();

    console.log(positions);
    setIsFetchingDialogDetails(false);
    setDialogDetails({ positions, practices });
  };

  const handleDialogClose = () => {
    if (isSubmitting) return;
    setIsDialogOpen(false);
  };

  const handleUserAdd = async (values) => {
    setIsSubmitting(true);
    const user = { 
      ...values,
      position: values.positionObj.description,
      positionId: values.positionObj.id,
      practiceName: values.practiceObj.name,
      practiceId: values.practiceObj.id
    }

    const body = {
      invitedUsers: [user],
      personnelId: userInfo.Id
    }

    try {
      const addResponse = await fetch(apiUrl + "locker/add", getAuthorizedFetchOptions("POST", body));
      const data = await addResponse.json();
      if (!addResponse.ok) {
        throw (data.error[0])
      }

      setIsDialogOpen(false);
      dispatch(openSnackbar({type: "success", title: "Success", body: "User successfully invited" }))
    } catch (err) {
      dispatch(openSnackbar({type: "error", title: "Something went wrong", body: err }))
    }

    setIsSubmitting(false);
  }

  return (
    <React.Fragment>
      <Header />
      <Container
        sx={{ marginY: "35px" }}
        maxWidth="lg"
        disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
      >
        <Box marginY={5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" component="h1">
              Users
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <StyledButton variant="primary" onClick={handleDialogOpen}>
                Add New User
              </StyledButton>
            </Stack>
          </Stack>

          <ContentContainer sx={{ marginY: 2 }}>
            {isLoading && (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            )}
            {!isLoading && (
              <>
                {users.length === 0 && (
                  <EmptyAlert resource="users" backPath="/users" />
                )}
                {users.length > 0 && (
                  <>
                    <DataTable headers={["Name", "Email", "Status", ""]}>
                      {users.map((user) => (
                        <TableRow
                          key={user.id}
                          row={[
                            `${user.firstName} ${user.lastName}`,
                            user.email,
                            user.status,
                            user.status === "Invited" ? (
                              <ResendUserInvite id={user.id} />
                            ) : (
                              ""
                            ),
                          ]}
                        />
                      ))}
                    </DataTable>
                    <Pagination
                      count={pagesCount}
                      page={currentPage}
                      onChange={handleClickPagination}
                      sx={{ marginY: 2 }}
                    />
                  </>
                )}
              </>
            )}
          </ContentContainer>
        </Box>
      </Container>
      <NewUserDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onUserAdd={handleUserAdd}
        isSubmitting={isSubmitting}
        isLoading={isFetchingDialogDetails}
        dialogDetails={dialogDetails}
      />
    </React.Fragment>
  );
};

export default Users;
