import {
  CircularProgress,
  Container,
  Pagination,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import StyledButton from "../../components/common/button/largeButton";
import ContentContainer from "../../components/common/contentContainer";
import DataTable from "../../components/common/table/dataTable";
import ExpandingTableRow from "../../components/common/table/expandingTableRow";
import Header from "../../components/header/header";
import InviteLabDialog from "../../components/labs/InviteLabDialog";
import { getAuthorizedFetchOptions } from "../../helpers/getAuthorizedFetchOptions";
import { apiUrl } from "../../constants";
import { getFetchOptions } from "../../helpers/getFetchOptions";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions";
import AdminService from "../../services/admin.service";
import usePaginationValues from "../../hooks/usePaginationValues";
import ResendLabInvite from "../../components/labs/resendLabInvite";
import EmptyAlert from "../../components/patient/emptyAlert";
import { useTheme } from "@mui/material/styles";

const Labs = () => {
    const theme = useTheme();
  const [labInviteSubmitting, setLabInviteSubmitting] = useState(false);
  const [fetchingDialogDetails, setFetchingDialogDetails] = useState(false);
  const [fetchingUserLabs, setFetchingUserLabs] = useState(false);
  const [inviteLabOpen, setInviteLabOpen] = useState(false);
  const [userLabs, setUserLabs] = useState([]);
  const [total, setTotal] = useState([]);
  const [details, setDetails] = useState({
    dentists: [],
    evidentLabs: [],
    practices: [],
  });
  const { currentPage, skip, pagesCount, handleChangePage } =
    usePaginationValues(10, total);

  const userInfo = useSelector((state) => state.userInfo);
  const isDentist = userInfo.Roles.includes("Dentist");
  const dispatch = useDispatch();

  useEffect(() => {
    if (labInviteSubmitting) return;
    const getLabsAndSpecialists = async () => {
      const options = getAuthorizedFetchOptions("GET");

      const url = new URL(
        apiUrl +
          (isDentist ? "dentist" : "locker") +
          "/getlabandspecialistinvites?"
      );
      const data = {
        skip: skip,
        take: 10,
      };

      if (isDentist) {
        data.personnelId = userInfo.Id;
      } else {
        data.email = userInfo.Email;
      }

      url.search = Object.keys(data)
        .map((key) => `${key}=${data[key]}`)
        .join("&");

      const response = await fetch(url, options);

      const { dataList, totalCount } = await response.json();
      setTotal(totalCount);
      setUserLabs(dataList);
      setFetchingUserLabs(false);
    };

    setFetchingUserLabs(true);
    getLabsAndSpecialists();
  }, [labInviteSubmitting, skip, userInfo, isDentist]);

  const handleLabInvite = async (values) => {
    setLabInviteSubmitting(true);

    try {
      const dentist = { ...values.dentist };
      delete values.dentist;
      delete values.showDentists;

      const response = await fetch(
        apiUrl + "lab/sendlabinvite",
        getAuthorizedFetchOptions("POST", {
          personnelId: userInfo.Id,
          dentistId: dentist.dentistId || userInfo.Id,
          personnelFirstName: dentist.dentistFirstName || userInfo.FirstName,
          personnelLastName: dentist.dentistLastName || userInfo.LastName,
          practiceName: "",
          ...values,
        })
      );
      const data = await response.json();
      if (data.statusCode !== 10) {
        throw data.error;
      }

      dispatch(
        openSnackbar({ title: "Success", body: "Invite successfully sent" })
      );
      setLabInviteSubmitting(false);
      setInviteLabOpen(false);
    } catch (err) {
      dispatch(
        openSnackbar({
          title: "Something went wrong",
          body: err[0],
          type: "error",
        })
      );
      setLabInviteSubmitting(false);
    }
  };

  const handleInviteLabOpen = async () => {
    setInviteLabOpen(true);
    setFetchingDialogDetails(true);

    const labsResponse = await fetch(
      `${apiUrl}lab/getevidentlabs`,
      getFetchOptions("GET")
    );
    const labs = await labsResponse.json();

    let dentists = [];
    if (!userInfo.Roles.includes("Dentist")) {
      const dentistResponse = await fetch(
        `${apiUrl}locker/getdentist?email=${userInfo.Email}`,
        getAuthorizedFetchOptions()
      );
      const { data } = await dentistResponse.json();
      dentists = data;
    }

    const practicesResponse = await AdminService.getPractices(userInfo.Id);
    const { practices } = await practicesResponse.json();

    const formattedPractices = practices.map(({ id, name }) => ({
      id,
      name,
    }));

    setDetails({
      evidentLabs: labs,
      practices: formattedPractices,
      dentists: dentists,
    });
    setFetchingDialogDetails(false);
  };

  const handleInviteLabClose = () => {
    if (labInviteSubmitting) return;
    setInviteLabOpen(false);
    setLabInviteSubmitting(false);
    setDetails({
      dentists: [],
      evidentLabs: [],
      practices: [],
    });
  };

  const handleClickPagination = (_, page) => {
    handleChangePage(page);
  };

  return (
    <React.Fragment>
      <Header />
      <Container
        sx={{ marginY: "35px" }}
        maxWidth="lg"
        disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
      >
        <Box marginY={5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" component="h1">
              Labs
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <StyledButton variant="primary" onClick={handleInviteLabOpen}>
                Invite Lab
              </StyledButton>
            </Stack>
          </Stack>
          <ContentContainer sx={{ marginY: 2 }}>
            {!fetchingUserLabs ? (
              <>
                {!userLabs.length > 0 && <EmptyAlert resource="labs" />}
                {userLabs.length > 0 && (
                  <>
                    <DataTable
                      headers={["Lab/Specialist Name", "Status", "Date", ""]}
                    >
                      {userLabs.map((specialist, i) => (
                        <ExpandingTableRow
                          key={i}
                          row={[
                            specialist.name,
                            specialist.invitationStatus
                              ? "Onboarded"
                              : "Pending Invite",
                            moment(specialist.updatedAt).format("ll"),
                          ]}
                          expandContent={
                            <Box sx={{ padding: 3 }}>
                              <Stack
                                spacing={2}
                                direction="row"
                                alignItems="center"
                              >
                                <Typography variant="h6">
                                  {specialist.name}
                                </Typography>
                                <Box>
                                  {!specialist.invitationStatus && (
                                    <ResendLabInvite id={specialist.id} />
                                  )}
                                </Box>
                              </Stack>
                              <Stack spacing={1} sx={{ marginTop: 2 }}>
                                {[
                                  specialist.contactPerson && {
                                    name: "Contact Person",
                                    value: specialist.contactPerson,
                                  },
                                  {
                                    name: "Email",
                                    value: specialist.email,
                                  },
                                  {
                                    name: "Phone",
                                    value: specialist.phone,
                                  },
                                ].map((details) => (
                                  <Box>
                                    <Typography sx={{ color: "#6B6B6B" }}>
                                      {details.name && details.name + ":"}
                                    </Typography>
                                    <Typography>{details.value}</Typography>
                                  </Box>
                                ))}
                              </Stack>
                            </Box>
                          }
                          disableLastCell
                        />
                      ))}
                    </DataTable>
                    <Pagination
                      count={pagesCount}
                      page={currentPage}
                      onChange={handleClickPagination}
                      sx={{ marginY: 2 }}
                    />
                  </>
                )}
              </>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </ContentContainer>
        </Box>
      </Container>
      <InviteLabDialog
        open={inviteLabOpen}
        labs={details.evidentLabs}
        practices={details.practices}
        dentists={details.dentists}
        showDentists={!userInfo.Roles.includes("Dentist")}
        submitting={labInviteSubmitting}
        loading={fetchingDialogDetails}
        onClose={handleInviteLabClose}
        onInvite={handleLabInvite}
      />
    </React.Fragment>
  );
};

export default Labs;
