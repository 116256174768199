import {
  Divider,
  Link,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Searchbar from "../../components/patients/searchBar";
import PracticeTabs from "../../components/patients/practiceTabs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AdminService from "../../services/admin.service";
import moment from "moment";
import PatientMenu from "../../components/patients/patientMenu";
import {
  useNavigate,
  useSearchParams,
  Link as RouterLink,
} from "react-router-dom";
import PatientService from "../../services/patient.service";
import DataTable from "../../components/common/table/dataTable";
import TableRow from "../../components/common/table/tableRow";
import usePaginationValues from "../../hooks/usePaginationValues";
import LargeButton from "../../components/common/button/largeButton";
import PatientDetailsDialog from "../../components/patient/patientDetailsDialog";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import PageLayout from "../../components/common/pageLayout";
import Header from "../../components/header/header";
import { getRandomInt } from "../../helpers/getRandomInt";
import TableSkeleton from "../../components/common/skeleton/tableSkeleton";
import EmptyAlert from "../../components/patient/emptyAlert";
import BannerImage from "../../images/banners/surgery_steps.png";
import { brandGray, brandLight } from "../../constants";
import { formatCaseStatus } from "../../helpers/formatCaseStatus";

const patientsPerPage = 20;

const Patients = () => {
  const [openNewPatient, setOpenNewPatient] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState();
  const [practices, setPractices] = useState([]);
  const [patients, setPatients] = useState([]);
  const [searchParams] = useSearchParams();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { currentPage, pagesCount, skip, handleChangePage } =
    usePaginationValues(patientsPerPage, total);
  const navigate = useNavigate();
  const theme = useTheme();

  const userInfo = useSelector((state) => state.userInfo) || {};

  const name = searchParams.get("name");

  useEffect(() => {
    if (!userInfo.Id) {
      navigate("/");
    }

    const getPractices = async () => {
      const practicesResponse = await AdminService.getPractices(userInfo.Id);
      const { practices } = await practicesResponse.json();

      const formattedPractices = practices.map(({ id, name }) => ({
        id,
        name,
      }));
      setPractices(formattedPractices);
      if (userInfo.Roles.includes("Dentist")) {
        let defaultPractice = practices.find((x) => x.isPrimary === true);
        console.log(defaultPractice);
        if (
          defaultPractice !== null &&
          defaultPractice !== undefined &&
          defaultPractice !== "undefined"
        ) {
          setSelectedPractice(defaultPractice.id);
        } else {
          setSelectedPractice(formattedPractices[0].id);
        }
      } else {
        setSelectedPractice(formattedPractices[0].id);
      }
    };

    getPractices();
  }, [userInfo.Id]);

  useEffect(() => {
    if (!selectedPractice) return;

    setIsLoading(true);
    setPatients([]);

    const getPatients = async () => {
      let casesResponse;
      if (userInfo.Roles.includes("Office Manager")) {
        // user is office manager
        const pids = userInfo.PracticeWithPersonnel.filter(
          (p) => p.practiceId === selectedPractice
        );
        casesResponse = await PatientService.getPatientAndCasesByLocker({
          lockerIds: pids,
          name: name,
          take: patientsPerPage,
          skip,
        });
        const { totalCount, patientPersonnelAndCasesList } =
          await casesResponse.json();

        setTotal(totalCount);
        setPatients(patientPersonnelAndCasesList);
      } else {
        // user is dentist
        var pId = userInfo.PracticeWithPersonnel.filter(
          (p) => p.practiceId === selectedPractice
        ).map((m) => m.personnelId);
        casesResponse = await PatientService.getPatientAndCases({
          personnelId: pId,
          practiceId: selectedPractice,
          name: name,
          take: patientsPerPage,
          skip,
        });
        const { totalCount, patientAndCasesList } = await casesResponse.json();
        setTotal(totalCount);
        setPatients(patientAndCasesList);
      }
      setIsLoading(false);
    };

    getPatients();
  }, [selectedPractice, name, skip, userInfo.Id, userInfo]);

  const handleSearch = (name) => {
    navigate("?name=" + name);
  };

  const handlePracticeChange = (id) => {
    setSelectedPractice(id);
  };

  const getCasesString = (cases) => {
    return `${cases} ${cases > 1 ? "Cases" : "Case"}`;
  };

  const headers = [
    "#",
    "Patient Name",
    !userInfo.Roles.find((x) => x === "Dentist") && "Doctor Name",
    "Last Case Submitted",
    "Last Case Status",
    "Last Updated",
  ];

  const rows = useMemo(
    () =>
      patients.map((patient, i) => [
        i + 1,
        `${patient.lastName}, ${patient.firstName}`,
        !userInfo.Roles.find((x) => x === "Dentist") && patient.doctorName,
        patient.caseTreatments.length > 0 ? (
          <Stack>
            {patient.caseTreatments.map((t, i) => (
              <Typography key={i} sx={{ fontWeight: "inherit" }}>
                {t.treatmentType}
              </Typography>
            ))}
          </Stack>
        ) : (
          "None"
        ),
        formatCaseStatus(patient.caseStatus),
        patient.updatedDate ? moment(patient.updatedDate).format("ll") : "N/A",
        <PatientMenu patient={patient} />,
      ]),
    [patients]
  );

  console.log(patients);

  const handleRowClicks = patients.map((patient) => {
    // var md5 = require('md5');
    // const path = PatientService.getOldPatientPageLink(patient.patientId, md5(userInfo?.LoginId + 'evident2020'));
    return function () {
      // window.location.href= path;
      navigate("/patients/" + patient.patientId);
    };
  });

  const handlePaginationClick = (event, value) => {
    handleChangePage(value);
  };

  const handleOpenNewPatient = () => {
    setOpenNewPatient(true);
  };

  const handleCloseNewPatient = () => {
    setOpenNewPatient(false);
  };

  const handleNewPatientSuccess = (patientId) => {
    console.log(patientId);
    navigate(patientId);
  };

  const skeletonCount = useMemo(() => getRandomInt(2, 20), [isLoading]);

  return (
    <>
      <Header activeTab="/patients" />
      <PageLayout
        header="Patients List"
        // banner={{
        //   image: BannerImage,
        //   link: "https://evidentdigital.blob.core.windows.net/public/Implant_surgery_brochure.pdf",
        // }}
      >
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Box>
            <PracticeTabs
              practices={practices}
              selected={selectedPractice}
              onPracticeChange={handlePracticeChange}
            />
          </Box>
          <Stack justifyContent="center" sx={{ paddingBottom: 4 }}>
            <LargeButton variant="primary" onClick={handleOpenNewPatient}>
              Add New Patient
            </LargeButton>
          </Stack>
        </Stack>
        {/* Body */}
        <Box
          sx={{
            paddingY: 2,
            paddingX: 4,
            marginBottom: 2,
            backgroundColor: "#fff",
            borderRadius: "0 1rem 1rem 1rem",
          }}
        >
          <Searchbar onSearch={handleSearch} />
          {isLoading && <TableSkeleton count={skeletonCount} />}
          {!isLoading && (
            <Box>
              {rows.length > 0 && (
                <>
                  <Divider
                    sx={{
                      border: 0,
                      borderBottom: "1px dashed #6C6C6C",
                    }}
                  />
                  <DataTable headers={headers}>
                    {rows.map((row, i) => (
                      <TableRow
                        key={i}
                        row={row}
                        onClick={handleRowClicks[i]}
                        disableLastCell
                      />
                    ))}
                  </DataTable>
                </>
              )}
              {!rows.length > 0 && <EmptyAlert resource="patients" />}
            </Box>
          )}
        </Box>
        <Pagination
          count={pagesCount}
          page={currentPage}
          onChange={handlePaginationClick}
        />
        <PatientDetailsDialog
          open={openNewPatient}
          onClose={handleCloseNewPatient}
          practiceId={selectedPractice}
          practiceName={
            practices.find((practice) => practice.id === selectedPractice)
              ?.name || null
          }
          userId={userInfo && userInfo.Id}
          onSuccess={handleNewPatientSuccess}
          isNewPatient
        />
      </PageLayout>
    </>
  );
};

export default Patients;
