import { Stack, Container, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { brandLight } from "../../constants";

const PageLayout = ({ banner, header, children }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ borderRadius: "24px" }}>
        {/* Banner */}
        <Box width="100%" position="relative">
          {
            banner && (
              <Stack
                width="100%"
                maxHeight="209px"
                bgcolor="#000"
                justifyContent="center"
                alignItems="center"
              >
                <a href={banner.link} target="_blank" rel="noreferrer">
                  <Box
                    component="img"
                    src={banner.image}
                    alt="patients-banner"
                    sx={{
                      display: { xs: "none", md: "inherit" },
                      maxWidth: "100%",
                    }}
                  />
                </a>
              </Stack>
            )
          }

          <Container
            sx={{ marginY: "35px" }}
            maxWidth="lg"
            disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
          >
            <Box>
              <Typography
                variant="h5"
                component="h2"
                display="inline"
                sx={{
                  fontWeight: '700',
                  color: "#0A0909",
                  fontSize: "26px",
                }}
                fontWeight={700}
              >
                {header}
              </Typography>
            </Box>
          </Container>
        </Box>

        <Container
          sx={{ marginY: "35px" }}
          maxWidth="lg"
          disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
        >
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 10,
              paddingX: 6,
              paddingY: 4,
              bgcolor: brandLight,
              borderRadius: "20px",
            }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PageLayout;
