import {
  Link,
  Box,
  Container,
  IconButton,
  Stack,
  Typography,
  Avatar,
  Grid,
  List,
  ListItem,
  Button,
  Skeleton, 
  useMediaQuery
} from "@mui/material";
import React, { useMemo } from "react";
import Header from "../../components/header/header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import LargeButton from "../../components/common/button/largeButton";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { Link as RouterLink, NavLink, useParams } from "react-router-dom";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CaseService from "../../services/case.service";
import CaseFiles from "../../components/caseTracker/caseFiles";
import CaseChat from "../../components/caseTracker/caseChat";
import CaseResult from "../../components/caseTracker/caseResult";
import SearchFilter from "../../components/caseTracker/searchFilter";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import { getRandomInt } from "../../helpers/getRandomInt";
import RoundedSkeleton from "../../components/common/skeleton/roundedSkeleton";
import {
  brandBlue,
  brandColor,
  brandGray,
  brandLight,
  brandLightGreen,
  brandRed,
} from "../../constants";

import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import LoadingModal from "../../components/common/modal/loadingModal";
import PdfPreview from "../../components/caseTracker/pdfPreview";
import DialogContainer from "../../components/common/dialogContainer";
import { openSnackbar } from "../../redux/actions";
import { useTheme } from "@mui/material/styles";

const Case = () => {
  const theme = useTheme();
  const [selectedCase, setSelectedCase] = useState({});
  const { caseId, activityNav } = useParams();
  const [treatmentSelected, setTreatmentSelected] = useState("");
  const [treatmentList, setTreatmentList] = useState([]);
  const [casePackages, setCasePackages] = useState([]);
  const [viewOrderOpen, setViewOrderOpen] = useState();
  const [pdfLoading, setPdfLoading] = useState();
  const [orderPdfLink, setOrderPdfLink] = useState();
  const [showViewOrder, setShowViewOrder] = useState(true);

  const [steps, setSteps] = useState([
    { description: "Orders Received", active: false },
    { description: "Case In Progress", active: false },
    { description: "Case For Dentist Approval", active: false },
    { description: "Finalizing Design", active: false },
    { description: "Completed", active: false },
  ]);

  const getPackageLabels = (productId, name) => {
    if (productId === 1002 || productId === 1249) {
      return "Diagnostic Wax Up";
    } else if (productId === 1054) {
      return "Trial Smile (One-piece veneer style to try in over the patient's existing teeth)";
    } else if (productId === 1234) {
      return "Virtually prepped shell temps (with a 0.6 mm virtual prep)";
    } else if (productId === 1235) {
      return "Prepped shell temps (chairside prep and IOS scan)";
    } else if (productId === 1236) {
      return "PDF Presentation";
    } else if (productId === 1243) {
      return "Appended Model on DX Design";
    } else if (productId === 1244) {
      return "Appended Model with Alternating Tooth";
    } else if (productId === 1016) {
      return "Gingival Reduction Guide";
    } else {
      return name;
    }
  };

  useEffect(() => {
    const getOrderPdf = async () => {
      setPdfLoading(true);
      const response = await CaseService.getorderpdf(caseId);
      const url = await response.json();
      try {
        let response = await fetch(url);
        if (!response.ok) {
          throw new Error();
        }
        let blob = await response.blob();
        setPdfLoading(false);
        setOrderPdfLink(URL.createObjectURL(blob));
      } catch (err) {
        setShowViewOrder(false);
        setPdfLoading(false);
        return;
      }
    };
    getOrderPdf();
    getCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  const getCase = async (status) => {
    const response = await CaseService.getCaseById(caseId);
    const data = await response.json();

    let task = [];

    let treatments = [];
    data.caseTreatments.forEach((ct, idx) => {
      setTreatmentSelected(ct.treatmentType);

      ct?.serviceProviders.forEach((sp) => {
        if (sp.lastModified) {
          sp.treatmentType = ct.treatmentType;
          task.push(sp);
        }
      });

      if (idx === 0) {
        let packages = [];
        ct?.casePackages.forEach((cp) => {
          packages = packages.concat(cp);
        });
        console.log(packages);
        setCasePackages(packages);
        console.log(packages);
      }
    });

    task.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.lastModified) - new Date(a.lastModified);
    });

    if (task.length < 1) {
      var tempLatestTask =
        data.caseTreatments && data.caseTreatments.length > 0
          ? data.caseTreatments[0].serviceProviders[0]
          : null;

      if (tempLatestTask) {
        tempLatestTask.status = "Sent";
        data.latestTask = tempLatestTask;
      }
    } else {
      data.latestTask = task[0];
    }

    treatments = treatments.concat({
      name: data.latestTask.treatmentType,
      count: 0,
      status: getStatusForUI(data.latestTask.status),
    });

    data.caseTreatments.forEach((ct) => {
      ct?.casePackages.forEach((cp) => {
        treatments = treatments.concat({
          name: cp.name,
          count: 0,
          status: getStatusForUI(cp.status),
        });
      });
    });

    data.latestTask.latestStatus = getStatusForUI(data.latestTask.status);

    console.log(data.latestTask);
    let newArr = steps.map((item, i) => {
      const newStatus = status || data.latestTask.latestStatus;
      if (item.description === newStatus) {
        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });

    setSteps(newArr);

    console.log("case get case", data); 
    
    localStorage.setItem('locSelectedDentistID', data.personnelId);
    setSelectedCase(data);
    setTreatmentList(treatments);
  };

  // useEffect(() => {
  //   console.log(treatmentSelected, treatmentList.length);
  //   if (treatmentSelected !== "" && treatmentList.length > 0) {
  //     console.log(treatmentList, treatmentSelected);

  //     let selected = treatmentList.find((x) => x.name === treatmentSelected);
  //     console.log(selected, treatmentSelected);
  //     let newArr = steps.map((item, i) => {
  //       if (item.description === selected.status) {
  //         return {
  //           ...item,
  //           active: true,
  //         };
  //       } else {
  //         return {
  //           ...item,
  //           active: false,
  //         };
  //       }
  //     });

  //     setSteps(newArr);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [treatmentSelected]);

  const getStatusForUI = (status) => {
    if (status === "Sent") {
      return "Orders Received";
    } else if (status === "In Progress" || status === "Shipped") {
      return "Case In Progress";
    } else if (status === "For Approval") {
      return "Case For Dentist Approval";
    } else if (status === "For Completion") {
      return "Finalizing Design";
    } else if (status === "Completed" || status === "Received") {
      return "Completed";
    } else if (status === "Cancelled") {
      return "Cancelled";
    }
  };

  const parentPath = `/cases/${caseId}/`;

  const navLinks = [
    { path: parentPath + `case-chat`, name: "Messages" },
    { path: parentPath + `case-result`, name: "Actions Needed from Dentist" },
    { path: parentPath + `case-files`, name: "Designs from Evident" },
  ];

  const handleStatusChange = (status) => {
    getCase(status);
  };

  const selectedFileType = activityNav || "case-result";
  let content;
  switch (selectedFileType) {
    case "case-chat":
      content = <CaseChat caseId={caseId} patientId={selectedCase.patientId} />;
      break;
    case "case-files":
      content = (
        <CaseFiles
          caseId={caseId}
          onStatusChange={handleStatusChange}
          caseRefNo={selectedCase.caseRefNo}
        />
      );
      break;
    case "case-result":
      content = (
        <CaseResult caseId={caseId} onStatusChange={handleStatusChange} />
      );
      break;
    default:
      content = "File type does not exist";
  }

  const handleTreatmentSelect = (value) => {
    setTreatmentSelected(value);
  };

  const handleViewOrderClick = () => {
    setViewOrderOpen(true);
  };

  const taskDetails = [
    {
      title: "Service",
      value: selectedCase.latestTask?.serviceProvider,
    },
    {
      title: "Service Provider",
      value: selectedCase.latestTask?.serviceProviderName,
    },
  ];

  const statusIcon = (status, row, iconType) => {
    console.log(status, row, iconType);
    let statuses = [];

    // if (iconType === "Actions Needed from Dentist") {
    //   if (status === "Case For Dentist Approval") {
    //     statuses.push(
    //       <NotificationsActiveRoundedIcon style={{ color: "#f53434" }} />
    //     );
    //   }
    // }

    if (iconType === "Messages") {
      let isUnread = false;

      row.caseComments.forEach((x) => x.isRead === false);
      row.caseComments.forEach((x) => {
        if (x.isRead === false) {
          isUnread = true;
        }
      });

      if (isUnread === true) {
        statuses.push(<PriorityHighIcon style={{ color: brandRed }} />);
      }
    }
    return (
      <Stack spacing={1} sx={{ fontSize: "1.3em" }}>
        {statuses}
      </Stack>
    );
  };

  return (
    <React.Fragment>
      <Header activeTab="/cases" />
      {/* Pdf preview dialog */}
      <DialogContainer
        open={viewOrderOpen}
        maxWidth="md"
        handleClose={() => setViewOrderOpen(false)}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography component="h2" variant="h6">
            Order Details
          </Typography>
        </Box>
        <Box sx={{ marginY: 2 }}>
          <PdfPreview link={orderPdfLink} />
        </Box>

        <Stack direction="row" alignItems="center">
          <Button
            color="brand"
            variant="contained"
            onClick={() => setViewOrderOpen(false)}
            sx={{ display: "block", marginLeft: "auto" }}
          >
            Close
          </Button>
        </Stack>
      </DialogContainer>

        <Container
          sx={{ marginY: "35px" }}
          maxWidth="lg"
          disableGutters={useMediaQuery(theme.breakpoints.up("lg"))}
        >
        <Box borderRadius="20px">
          <Grid container marginY={5}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box maxWidth="md">
                  <Stack direction="row" alignItems="center" paddingBottom={1}>
                    <IconButton component={RouterLink} to="/cases">
                      <ArrowBackIcon fontSize="large" sx={{ color: "black" }} />
                    </IconButton>
                    <Typography variant="h4" fontWeight="300">
                      Case Details
                    </Typography>
                  </Stack>
                </Box>
                {/* <LargeButton
                  component={RouterLink}
                  to="/cases"
                  variant="outlined"
                >
                  See All Cases
                </LargeButton> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          paddingY={0.5}
          marginTop={2}
          marginBottom={1.5}
          borderRadius="20px"
          sx={{ bgcolor: "rgba(255, 255, 255, 0.5)" }}
        >
          <Grid container marginY={5}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  paddingBottom={2}
                >
                  <Avatar sx={{ height: "120px", width: "120px" }} />
                  {!Object.keys(selectedCase).length > 0 && (
                    <Box>
                      <RoundedSkeleton
                        width={`${getRandomInt(200, 350)}px`}
                        height="42px"
                        sx={{
                          marginBottom: 1,
                        }}
                      />
                      <RoundedSkeleton
                        width={`${getRandomInt(180, 300)}px`}
                        height="32px"
                      />
                    </Box>
                  )}
                  {Object.keys(selectedCase).length > 0 && (
                    <Box sx={{ fontSize: "1.3em" }}>
                      <Box mt={0} mb={0.75}>
                        <Typography
                          variant="h4"
                          color="#5B5B5B"
                          fontWeight="bold"
                        >
                          No. {selectedCase.caseRefNo}
                        </Typography>
                      </Box>
                      <Box my={0}>
                        {selectedCase?.treatment?.map((cp, idx) => (
                          <Typography
                            key={idx}
                            color="#5B5B5B"
                            variant="h5"
                            marginY={0}
                          >
                            {cp}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Stack>

                {selectedCase.latestTask && (
                  <Stack alignItems="flex-end">
                    {pdfLoading && (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          borderRadius: "30px",
                          height: "60px",
                          width: "100%",
                          maxWidth: "182px",
                        }}
                      />
                    )}
                    {!pdfLoading && (
                      <Box>
                        {showViewOrder && (
                          <LargeButton
                            variant="primary"
                            onClick={handleViewOrderClick}
                          >
                            View Order
                          </LargeButton>
                        )}
                      </Box>
                    )}
                    <Stack direction="row" justifyContent="space-between">
                      {taskDetails.map((details) => (
                        <Box
                          sx={{
                            width: "max-content",
                            marginX: "40px",
                            paddingY: 2,
                            marginTop: 2,
                          }}
                        >
                          <Typography sx={{ color: "#878787" }}>
                            {details.title}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 500,
                              color: "#5B5B5B",
                            }}
                          >
                            {details.value}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Grid>
            {/* <Grid item xs={12}>
              <SearchFilter
                filterList={treatmentList}
                onSelect={handleTreatmentSelect}
                searchFilter={treatmentSelected}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Box
                paddingY={1}
                paddingTop={3}
                paddingBottom={3}
                borderRadius="20px"
                sx={{ bgcolor: brandLight }}
              >
                <Typography
                  align="center"
                  variant="h5"
                  color="#5B5B5B"
                  fontWeight="bold"
                >
                  Case Progress
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                    marginTop: 2,
                    "MuiStepLabel-label .MuiStepLabel-alternativeLabel": {
                      fontSize: "15px",
                      marginTop: "23px !important",
                    },
                    ".MuiSvgIcon-root": {
                      fontSize: "3rem",
                      color: brandGray,
                    },
                    ".MuiStepConnector-root": {
                      top: "25px",
                      left: "calc(-50% + 30px)",
                      right: "calc(50% + 30px)",
                    },
                    ".Mui-active": {
                      color: brandColor + " !important",
                    },
                    ".Mui-completed": {
                      color: brandColor + " !important",
                    },
                    ".MuiStepLabel-label .Mui-active .MuiStepLabel-alternativeLabel":
                      {
                        marginTop: "2px",
                      },
                  }}
                >
                  <Stepper
                    sx={{}}
                    activeStep={steps.findIndex((x) => x.active)}
                    alternativeLabel
                  >
                    {steps.map((step) => (
                      <Step key={step.description}>
                        <StepLabel>
                          {step.active && (
                            <Box
                              sx={{
                                background: brandColor,
                                color: "#fff",
                                borderRadius: "18px",
                                display: "flex",
                                justifyContent: "center",
                                margin: "auto",
                                width: "70%",
                                padding: "0px",
                              }}
                              //   onClick={() => setIsSameDayDentistry(true)}
                            >
                              {step.description}
                            </Box>
                          )}
                          {!step.active && step.description}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>

                {casePackages.length > 0 && (
                  <Stack
                    spacing={1}
                    sx={{
                      fontSize: "1.3em",
                      marginTop: "20px",
                      marginLeft: "40px",
                    }}
                  >
                    <Typography
                      align="left"
                      variant="h5"
                      color="#5B5B5B"
                      fontWeight="bold"
                    >
                      Package Inclusions:
                    </Typography>
                    {casePackages.map((cp, idx) => (
                      <div
                        key={idx}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {getStatusForUI(cp.status) === "Completed" ? (
                          <CheckCircleRoundedIcon
                            style={{ color: brandColor }}
                          />
                        ) : getStatusForUI(cp.status) ===
                          "Case For Dentist Approval" ? (
                          <NotificationsActiveRoundedIcon
                            style={{ color: brandRed }}
                          />
                        ) : getStatusForUI(cp.status) === "Orders Received" ? (
                          <CallReceivedIcon
                            style={{ color: brandLightGreen }}
                          />
                        ) : getStatusForUI(cp.status) === "Case In Progress" ? (
                          <AutorenewIcon style={{ color: brandBlue }} />
                        ) : getStatusForUI(cp.status) ===
                          "Finalizing Design" ? (
                          <DesignServicesIcon
                            style={{ color: brandLightGreen }}
                          />
                        ) : (
                          <span style={{ padding: "12px" }}></span>
                        )}

                        <span>
                          {getPackageLabels(cp.productId, cp.name)} -{" "}
                          {getStatusForUI(cp.status) === "Orders Received"
                            ? "Order Received"
                            : cp.status === "For Completion"
                            ? "Approved"
                            : getStatusForUI(cp.status)}
                        </span>
                        <span></span>
                      </div>
                    ))}
                  </Stack>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: "20px",
                marginTop: "15px",
                paddingTop: "38px",
                paddingBottom: "48px",
                paddingX: "48px",
                backgroundColor: brandLight,
              }}
            >
              <List component={Stack} direction="row" sx={{ padding: "0px" }}>
                {navLinks.map((navLink) => (
                  <ListItem
                    to={navLink.path}
                    component={NavLink}
                    key={navLink.path}
                    // style={({ isActive }) =>
                    //   isActive
                    //     ? {
                    //         borderRadius: "20px 20px 0px 0px",
                    //         borderLeft: "7px",
                    //         paddingRight: "7px",
                    //         fontWeight: "bold",
                    //         paddingBottom: "7px solid" + brandLightGreen,
                    //         backgroundColor: "#EBECF0",
                    //         boxShadow: "inset 0px -10px 0px -5px #32be60",
                    //       }
                    //     : {
                    //         borderLeft: "7px",
                    //         paddingRight: "7px",
                    //         fontWeight: "500",
                    //       }
                    // }
                    sx={{
                      marginRight: "16px",
                      padding: "10px",
                      height: "58px",
                      width: "190px",
                    }}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            paddingX: 6,
                            paddingTop: 2,
                            paddingBottom: 1.7,
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "16px",
                            textTransform: "none",
                            backgroundColor: brandLightGreen,
                            "&:hover": {
                              backgroundColor: brandLightGreen,
                            },
                            borderTopLeftRadius: "14px",
                            borderTopRightRadius: "14px",
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                          }
                        : {
                            paddingX: 6,
                            paddingTop: 2,
                            paddingBottom: 1.7,
                            color: brandGray,
                            fontWeight: "400",
                            fontSize: "16px",
                            textTransform: "none",
                            backgroundColor: "#fff",
                            "&:hover": {
                              backgroundColor: "#fff",
                            },
                            borderTopLeftRadius: "14px",
                            borderTopRightRadius: "14px",
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                          }
                    }
                  >
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {navLink.name}
                    </Box>

                    {selectedCase &&
                      selectedCase?.latestTask?.latestStatus &&
                      statusIcon(
                        getStatusForUI(selectedCase?.latestTask?.latestStatus),
                        selectedCase,
                        navLink.name
                      )}
                  </ListItem>
                ))}
              </List>
              <Box
                sx={{
                  paddingY: 2,
                  paddingX: 4,
                  borderRadius: "1rem",
                  backgroundColor: "#fff",
                  borderRadius: "0 1rem 1rem 1rem",
                  textAlign: "center",
                }}
              >
                {content}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Case;
