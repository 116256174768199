import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store, persistor } from "./redux/store/index";
import { PersistGate } from "redux-persist/integration/react";

import fetchIntercept from "fetch-intercept";

import AccountService from "./services/account.service";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { brandBlue, brandColor, brandOrange, brandRed } from "./constants";
import { useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { updatePatientId } from "./redux/actions";

const unregister = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    return [url, config];
  },

  requestError: function (error) {
    console.log("request", error);
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object

    if (response.status === 401) {
      AccountService.logOut(true);
      window.localStorage.clear();
      window.location.href = "/";
    }

    return response;
  },

  responseError: function (error) {
    console.log("response", error);
    // Handle an fetch error
    return Promise.reject(error);
  },
});

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const customColors = [
  "brand",
  "brandColor",
  "brandBlue",
  "brandRed",
  "brandOrange",
];
const theme = createTheme({
  palette: {
    brand: createColor(brandColor),
    brandBlue: createColor(brandBlue),
    brandRed: createColor(brandRed),
    brandOrange: createColor(brandOrange),
  },
  typography: {
    fontFamily: [
      "Inter", 
      "Aileron",
      "Heebo",
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          ...(ownerState.variant === "contained" &&
            customColors.includes(ownerState.color) && { color: "#fff" }),
        }),
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
