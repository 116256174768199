import { Box, Container, Divider, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { brandBlue } from "../../constants";
import defaultLogo from "../../images/EviSmartLogo.png"; //"../../images/SmartClinicLogo.png";
import { useSelector } from "react-redux";
import Facebook from "../../images/social-media/fb.png";
import Insta from "../../images/social-media/insta.png";
import Linkedin from "../../images/social-media/linkedin.png";
import Youtube from "../../images/social-media/yt.png";
import Tiktok from "../../images/social-media/tiktok.png";

const Layout = ({ children }) => {
  const loggedIn = Boolean(useSelector((state) => state.userInfo));
  const homeLink = loggedIn
    ? process.env.REACT_APP_EVIDENT_MAIN_LOG_IN
    : process.env.REACT_APP_EVIDENT_MAIN_URL;
  const aboutLink = loggedIn
    ? process.env.REACT_APP_EVIDENT_ABOUT_URL
    : process.env.REACT_APP_EVIDENT_MAIN_URL + "/about-us";


    const _faqUrl = process.env.REACT_APP_EVIDENT_FAQ_URL;
  
    const _mainWebsiteUrl = loggedIn
    ? process.env.REACT_APP_EVIDENT_MAIN_LOG_IN
    : process.env.REACT_APP_EVIDENT_MAIN_URL;

    const _faqUnauthorized = _mainWebsiteUrl + "/faq";


  const socials = [
    {
      image: Facebook,
      link: "https://www.facebook.com/evidentdigital",
    },
    {
      image: Insta,
      link: "https://www.instagram.com/evidentdigital/",
    },
    {
      image: Linkedin,
      link: "https://www.linkedin.com/company/evidentdigital/",
    },
    {
      image: Youtube,
      link: "https://www.youtube.com/@evidentdigital",
    },
    {
      image: Tiktok,
      link: "https://www.tiktok.com/@evidentdigital",
    },
  ];

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {children}
      <div style={{ paddingTop: "2rem" }}></div>
      <footer
        style={{
          boxSizing: "border-box",
          backgroundColor: "#FAFAFA",
          marginTop: "auto",
          display: "flex",
        }}
      >
        <Container maxWidth="xl" disableGutters>
          
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "45px" }}
            >
              <Box component="a" href={homeLink} sx={{ marginRight: "50px" }}>
                <img
                  src={defaultLogo}
                  alt="Evident"
                  style={{ maxHeight: "44px" }}
                />
              </Box>


              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: "45px" }}
              >
                <Box
                  component="a"
                  href={'/treatments'}
                  sx={{
                    lineHeight: "1.5",
                    textDecoration: "none",
                    color: "white",
                    "&: hover": {
                      color: brandBlue,
                    },
                  }}
                >
                  <Typography color={"#555555"} fontSize={{xs: "14px", lg: "14px"}}>Treatments</Typography>
                </Box>
                <Box
                  component="a"
                  href={'/cases'}
                  sx={{
                    marginLeft: "40px",
                    lineHeight: "1.5",
                    textDecoration: "none",
                    color: "white",
                    "&: hover": {
                      color: brandBlue,
                    },
                  }}
                >
                  <Typography color={"#555555"} fontSize={{xs: "14px", lg: "14px"}}>Case Management</Typography>
                </Box>
                <Box
                  component="a"
                  href="/patients"
                  sx={{
                    marginLeft: "40px",
                    lineHeight: "1.5",
                    textDecoration: "none",
                    color: "white",
                    "&: hover": {
                      color: brandBlue,
                    },
                  }}
                  rel="noreferrer"
                >
                  <Typography color={"#555555"} fontSize={{xs: "14px", lg: "14px"}}>Patients</Typography>
                </Box>
                <Box
                  component="a"
                  href={loggedIn ? _faqUrl : _faqUnauthorized}
                  sx={{
                    marginLeft: "40px",
                    lineHeight: "1.5",
                    textDecoration: "none",
                    color: "white",
                    "&: hover": {
                      color: brandBlue,
                    },
                  }}
                  rel="noreferrer"
                >
                  <Typography color={"#555555"} fontSize={{xs: "14px", lg: "14px"}}>FAQ</Typography>
                </Box>

              </Stack>
            </Stack>
            <Box
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                marginTop: "20px",
                color: "#767676",
                marginBottom: '50px'
              }}
            >
              The first Ai powered dental platform for scale. Sync to<br/> an entire network of dental labs, clinics, & patients.
            </Box>
            

            
            <Stack
              sx={{marginY: '40px'}}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                spacing={3}
                alignItems="center"
              >
                {socials.map((social) => (
                  <a href={social.link} target="_blank" rel="noreferrer" color="black">
                    <img src={social.image} alt={social.link}/>
                  </a>
                ))}
              </Stack>
              
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Box
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "26px",
                    marginTop: "20px",
                    color: "#111111",
                  }}
                >
                  Copyright © Evident inc.
                </Box>

                <Link
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "18px",
                    marginTop: "20px",
                    color: "#999999",
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://assets.evidentdigital.com/cms/Evismart_Privacy_Policy_3296c6f6aa.pdf"
                  underline="hover"
                >
                  Privacy Policy
                </Link>
                <Box
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "26px",
                    marginTop: "20px",
                    color: "#999999",
                  }}
                >
                    |
                </Box>
                <Link
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "18px",
                    marginTop: "20px",
                    color: "#999999",
                    cursor: 'pointer'
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://assets.evidentdigital.com/cms/Terms_of_Use_f4eb42d7ed.pdf"
                  underline="hover"
                >
                  Terms of Use
                </Link>
              </Stack>
              
            </Stack>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;
